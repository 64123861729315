import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import { Link } from 'gatsby';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "INSTAR Command, Status and RAW Topics",
  "path": "/Frequently_Asked_Question/INSTAR_Command_Status_and_RAW_Topics/",
  "dateChanged": "2020-03-23",
  "author": "Mike Polinowski",
  "excerpt": "I want to switch my cameras Alarm Areas through the INSTAR MQTT Interface but I don't understand how to use the corresponding MQTT Topic.",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='FAQs - INSTAR Command, Status and RAW Topics' dateChanged='2020-03-23' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to switch my cameras Alarm Areas through the INSTAR MQTT Interface but I don`t understand how to use the corresponding MQTT Topic.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/INSTAR_Command_Status_and_RAW_Topics/' locationFR='/fr/Frequently_Asked_Question/INSTAR_Command_Status_and_RAW_Topics/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "instar-command-status-and-raw-topics",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-command-status-and-raw-topics",
        "aria-label": "instar command status and raw topics permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Command, Status and RAW Topics`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I want to switch my cameras `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Areas/"
      }}>{`Alarm Areas`}</a>{` through the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`INSTAR MQTT Interface`}</a>{` but I don't understand how to use the corresponding `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTT_API/"
      }}>{`MQTT Topic`}</a>{`.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`A`}</strong>{`: The corresponding `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTT_API/"
      }}>{`MQTT Topics`}</a>{` to switch the areas 1-4 are `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/area1/enable`}</code>{` -  `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/area4/enable`}</code>{`. And they expect a payload `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{` to activate the area and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"0"}`}</code>{` to deactivate it. If you cannot find the correct topic in our list, you can also connect a `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
      }}>{`Debugging Tool like MQTT.fx or MQTT Explorer`}</a>{` to your camera's MQTT broker and use the webUI to update those topics. The example below shows the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Areas/"
      }}>{`Alarm Areas Menu`}</a>{` and below the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/#mqttfx"
      }}>{`MQTT.fx`}</a>{`. Just click on submit in the camera webUI and you will see all MQTT updates that are triggered in MQTT.fx:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/4015046c3d2ee4e9b77c32c5c6102b6d/INSTAR_MQTT_01.gif",
        "alt": "INSTAR MQTT Explorer for ioBroker"
      }}></img></p>
    <p>{`Now that you found your MQTT Topic you now need to make sure that it is directed at the correct camera. As we said, we need to update the topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/area1/enable`}</code>{`. The complete `}<strong parentName="p">{`Command Topic`}</strong>{` for my camera is:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`instar/10D1DC218F96/alarm/area1/enable`}</code></pre></div>
    <p>{`The value `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`10D1DC218F96`}</code>{` is the `}<strong parentName="p">{`MQTT ID`}</strong>{` of my personal camera and will differ in your case. The MQTT ID is generated from the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/System/Info/"
      }}>{`LAN MAC Address`}</a>{` of your camera.`}</p>
    <p>{`If you only have one camera connected to your MQTT Broker you can simplify this by swapping the MQTT ID with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`local`}</code>{`. The `}<strong parentName="p">{`Local Topic`}</strong>{` will target the camera that runs your MQTT Broker:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`instar/local/alarm/area1/enable`}</code></pre></div>
    <p>{`The topic in this case requires a JSON formatted payload, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{`. We can further simplify our setup by using the `}<strong parentName="p">{`RAW Topic`}</strong>{` instead. This way we can use the raw value of the payload instead of having to bother ourselves with JSON formatting - in this case the raw payload is simply `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`instar/local/alarm/area1/enable/raw`}</code></pre></div>
    <p><img parentName="p" {...{
        "src": "/en/e9008dc6237a2527431010c75b78cb25/INSTAR_MQTT_02.gif",
        "alt": "INSTAR MQTT Explorer for ioBroker"
      }}></img></p>
    <p>{`When we publish this update we can see both the RAW and regular `}<strong parentName="p">{`Command Topic`}</strong>{` in MQTT.fx. And shortly afterwards we receive the `}<strong parentName="p">{`Status Update`}</strong>{` from our camera, telling us that the camera setting was updated successfully.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`instar/10D1DC218F96/status/alarm/area1/enable`}</code></pre></div>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      